
class SearchHelper {
    constructor(vm) {
        this.vm = vm
        this.adapter = this.vm.type === 'invite' ? new user() : new member();
        this.lists = []
        this.limit = this.adapter.limit
    }
    setList(lists) {
        this.lists = lists
    }
    onSearch(keyword) {
        return this.adapter.onSearch(this, keyword);
    }
    onSubmit(uids) {

        const that = this.vm

        const path = that.type === 'invite' ? `api/group/${that.groupid}/invite/member` : `api/group/${that.groupid}/member-role/set-admin`;

        const requests = uids.map(
            (uid) => new Promise((resolve) => {
                const url = `${path}/${uid}`;
                that.$request.get(url).then((res) => {
                    resolve(res);
                });
            }),
        );

        Promise.all(requests)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
        this.onCancel();

        // this.adapter.onSubmit(this.vm, uids);
    }
    onCancel() {
        // this.adapter.onCancel();
        this.vm.$router.push({
            path: `/group/${this.vm.groupid}/members`,
        });
    }
    // 获取已经成为的列表
    getCurrentUsed(callback) {
        const currentList = this.adapter.currentList(this.vm);
        if (typeof callback === 'function') {
            callback(currentList);
        }
        return currentList

    }
}

// 用户好友
class user {
    constructor() {
        this.limit = 20;
    }
    onSearch(that, keyword) {
        const result = that.lists.filter((item) => {
            const nickname = item.info.nickname.match(keyword);
            const comment = item.comment.match(keyword);
            return !(nickname === null && comment === null);
        });
        return result;
    }
    onSubmit(vm, uids) {
        const that = vm;
        const requests = uids.map(
            (uid) => new Promise((resolve) => {
                const url = `api/group/${that.groupid}/invite/member/${uid}`;
                that.$request.get(url).then((res) => {
                    resolve(res);
                });
            }),
        );
        Promise.all(requests)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
        that.onCancel();
    }
    onCancel() { }
    currentList(vm) {
        const that = vm;
        // 获取邀请记录
        return new Promise((resolve) => {
            that.$request.get(`api/group/${that.groupid}/invite/records`).then(res => {
                let records = res.data.lists
                if (records) {
                    records = Object.values(records)
                } else {
                    records = []
                }
                resolve(records.filter(item => {
                    return Number(item.handler_status) === 1 || Number(item.handler_status) === 0
                }))
            })
        })
    }
}
// 小组成员
class member {
    constructor() {
        this.limit = 3;
    }
    onSearch(that, keyword) {
        return that.lists.filter(item => {
            const searchNickname = item.nickname.match(keyword)
            return searchNickname !== null
        })
    }
    onSubmit(vm, uids) { }
    onCancel() { }
    currentList(vm) {
        const that = vm
        // 获取当前的管理员列表
        return new Promise(resolve => {
            that.$request.get(`api/group/${that.groupid}/member-role/admins`).then(res => {
                resolve(res.data)
            })
        })
    }
}

export default SearchHelper