<template>
  <div class="common-search-list-container user-search">
    <member-search
      @search="onSearch"
      @cancel="onCancel"
      @submit="onSubmit"
      :type="renderType"
      :lists="handleData"
      :max-limit="limit"
    ></member-search>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchHelper from '@/libs/search';
import memberSearch from '../common/_member-search.vue';

export default {
  name: 'page-member-search',
  components: {
    memberSearch,
  },
  data() {
    return {
      handleData: null,
      record: null,
      limit: 0,
      searching: false,
      helper: null,
    };
  },
  computed: {
    ...mapState(['friends']),
    lists() {
      return this.friends && this.type === 'invite' ? this.friends : null;
    },
    groupid() {
      return this.$route.query.groupid;
    },
    leaderuid() {
      return this.$route.query.leaderuid;
    },
    type() {
      return this.$route.query.type;
    },
    renderType() {
      return this.type === 'invite' ? 'group-invite' : 'admin-set-up';
    },
  },
  watch: {
    lists: {
      handler(val) {
        const that = this;
        if (val) {
          that.getMembersRecord().then((records) => {
            that.handleData = val.map((item) => {
              const temp = item;
              if (records) {
                temp.ex = records[item.info.uid] ? records[item.info.uid] : null;
              } else {
                temp.ex = null;
              }
              if (Number(temp.info.uid) === Number(that.leaderuid)) {
                temp.ex = {
                  status: 1,
                  handler_status: 99,
                };
              }
              return temp;
            });
          });
        } else {
          that.handleData = null;
        }
      },
      immediate: true,
    },
    handleData(val) {
      this.helper.setList(val);
    },
  },
  mounted() {
    const that = this;
    that.helper = new SearchHelper(that);
    that.helper
      .getCurrentUsed((currenLists) => {
        if (that.type === 'admins') {
          const requestGetMembers = that.getMembers();
          const requestGetCurrentAdmins = currenLists;
          Promise.all([requestGetMembers, requestGetCurrentAdmins]).then((res) => {
            const currentMemebers = res['0'].data;
            const currentAdminsUids = res['1'].map((item) => Number(item.uid));
            that.handleData = currentMemebers
              .filter((item) => Number(item.uid) !== Number(that.leaderuid))
              .map((item) => {
                const temp = item;
                temp.ex = currentAdminsUids.indexOf(Number(item.uid)) >= 0;
                return temp;
              });
            that.helper.setList(that.handleData);
          });
        }
      })
      .then((res) => {
        that.limit = that.helper.limit - res.length;
      });
  },
  methods: {
    getMembersRecord() {
      return new Promise((resolve, reject) => {
        const that = this;
        that.$request
          .get(`api/group/${that.groupid}/invite/records`)
          .then((res) => {
            resolve(res.data.lists);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getMembers() {
      const that = this;
      // that.$request.get(`api/group/${that.groupid}/member/list`).then(res => {
      //   that.handleData = res.data.filter(item => Number(item.uid) !== Number(that.leaderuid));
      // });
      return that.$request.get(`api/group/${that.groupid}/member/list`);
    },
    onSearch(keyword) {
      this.searching = true;
      const result = this.helper.onSearch(keyword);
      this.handleData = result;
    },
    onSubmit(uids) {
      this.helper.onSubmit(uids);
    },
    onCancel() {
      this.helper.onCancel();
    },
  },
};
</script>

<style></style>
